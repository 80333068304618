<template>
  <v-card>
    <v-card-title class="align-start">
      Change Password
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="data.email"
                label="E-mail"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="data.password"
                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show_password ? 'text' : 'password'"
                label="New Password"
                hint="At least 8 characters"
                counter
                required
                tabindex="1"
                @click:append="show_password = !show_password"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="data.password_confirmation"
                :append-icon="show_password_confirmation ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show_password_confirmation ? 'text' : 'password'"
                label="Password Confirmation"
                counter
                required
                tabindex="2"
                @click:append="show_password_confirmation = !show_password_confirmation"
                @keydown.enter="savePassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-btn color="primary" @click="savePassword" tabindex="3">
              Save
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    valid: false,
    user_id: 0,
    data: {
      email: '',
      password: '',
      password_confirmation: '',
    },
    path: 'user',
    show_password: false,
    show_password_confirmation: false,
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
    },
  }),
  async mounted() {
    if (this.$route.name.includes('usps')) {
      this.path = 'usps-reps'
    } else if (this.$route.name.includes('pb')) {
      this.path = 'pb-reps'
    }

    const { id } = this.$route.params
    this.user_id = id
    const token = this.$store.state.token
    const url = process.env.VUE_APP_API_URL

    const { data } = await axios.get(`${url}/api/${this.path}/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (this.path === 'user') {
      this.data.email = data.user.email
    } else if (this.path === 'usps-reps') {
      this.data.email = data.rep.email
    } else if (this.path === 'pb-reps') {
      this.data.email = data.email
    }
  },

  methods: {
    async savePassword () {
      let color = 'error'
      let msg = 'Data are still invalid!'

      this.$refs.form.validate()

      try {
        if (this.valid) {
          const token = this.$store.state.token
          const url = process.env.VUE_APP_API_URL

          const { data: { message } } = await axios
            .patch(
              `${url}/api/change-password/${this.user_id}`,
              this.data,
              { headers: { Authorization: `Bearer ${token}` } }
            )

          color = 'success'
          msg = message

          let path_name = 'accounts-show'

          if (this.path === 'usps-reps') {
            path_name = 'usps-show'
          } else if (this.path === 'pb-reps') {
            path_name = 'pb-show'
          }

          this.$router.push({ name: path_name, params: { id: this.user_id } })
        }
      } catch (error) {
        console.log(error);
      }

      this.$root.snackbar.show({ message: msg, color: color });
    },
  },
}
</script>
